import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_anp_column_item = _resolveComponent("anp-column-item")!
  const _component_anp_column_loader = _resolveComponent("anp-column-loader")!
  const _component_anp_column = _resolveComponent("anp-column")!

  return (_openBlock(), _createBlock(_component_anp_column, {
    title: _ctx.$t('search.search-results'),
    square: _ctx.square,
    ref: 
      (element) => {
        _ctx.root = element?.$el;
      }
    
  }, _createSlots({
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_anp_column_item, {
          key: item.id,
          item: item,
          resourceId: "null",
          articleRouteName: _ctx.SEARCH_ARTICLE_AI,
          articleIds: _ctx.itemsIds,
          showDescription: _ctx.showDescription,
          searchContext: true,
          hideEventTime: "",
          fullDate: "",
          showMeta: "",
          showSource: ""
        }, null, 8, ["item", "articleRouteName", "articleIds", "showDescription"]))
      }), 128)),
      (_ctx.loadingMore)
        ? (_openBlock(), _createBlock(_component_anp_column_loader, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.count)
      ? {
          name: "actions",
          fn: _withCtx(() => [
            _createVNode(_component_va_chip, { size: "small" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.count), 1)
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1032, ["title", "square"]))
}