
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  ARTICLE_RELATIONSHIP,
  ARTICLE_TYPES,
  RESOURCE_KINDS,
} from '@/config/enums';
import useFeatures from '@/hooks/useFeatures';
import useMessage from '@/hooks/useMessage';
import api from '@/services/api';
import { Article } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';
import { State } from '@/store';

import Calendar from './Calendar.vue';
import Media from './Media.vue';

export default defineComponent({
  emits: [
    'containsIframe',
    'containsNoIframe',
    'analysisLoadingStart',
    'analysisLoadingEnd',
    'analysisLoadingError',
  ],
  components: {
    Calendar,
    Media,
  },

  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
    docked: {
      type: Boolean,
      required: true,
    },
    overviewRouteName: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore<State>();
    const { t } = useI18n({});
    const router = useRouter();
    const route = useRoute();
    const feedbackLoading = ref(false);
    const translationLoading = ref(false);
    const analysisLoading = ref(false);
    const baseUri = computed(() => store.state.global.api.uri);
    const { message, setMessage, clearMessage } = useMessage();

    const pubDate = computed(
      () => props.article.pubDate && new Date(props.article.pubDate),
    );

    const translatedBody = ref('');

    const translatedBodyId = ref('');

    const showOriginalBodyButton = computed(
      () =>
        (props.article.origTitle && props.article.language == 'en') ||
        (translatedBody.value && translatedBodyId.value == props.article.id),
    );

    const showTranslateButton = computed(
      () =>
        useFeatures().features.value?.aiSearch &&
        translateArticleLanguage.value &&
        (translatedBody.value == '' ||
          translatedBodyId.value != props.article.id),
    );

    const showAnalysisButton = computed(
      () =>
        useFeatures().features.value?.aiSearch &&
        useFeatures().features.value?.analyseArticles &&
        props.article.relationships.includes(
          ARTICLE_RELATIONSHIP.OTHERAGENCIES,
        ) &&
        translatedBody.value == '',
    );

    const resourceId = computed(() => {
      return props.resource ? props.resource.resourceId : undefined;
    });

    const formattedDate = computed(() => {
      return pubDate.value
        ? format(pubDate.value, 'EEEE dd MMMM yyyy', { locale: nl })
        : undefined;
    });

    const formattedTime = computed(
      () => pubDate.value && format(pubDate.value, 'HH:mm'),
    );

    const termsLinks = computed(() =>
      props.article.links
        ? props.article.links.filter((link) => link.kind === 'terms')
        : [],
    );

    const iframeLink = computed(() =>
      props.article.links?.find((item) => item.kind === 'iframe'),
    );

    if (iframeLink.value) {
      emit('containsIframe');
    } else {
      emit('containsNoIframe');
    }

    const handleClose = () => {
      router.push({
        name: props.overviewRouteName,
        params: {
          id: route.params.id,
          resourceId: route.params.resourceId,
        },
      });
    };

    const handleThumbsUp = () => {
      feedbackLoading.value = true;

      store
        .dispatch('queries/feedback', {
          queryId: props.resource?.resourceId,
          articleId: props.article.id,
          score: route.query.score,
          ok: true,
        })
        .finally(() => {
          feedbackLoading.value = false;
        });

      handleClose();
    };

    const handleThumbsDown = () => {
      feedbackLoading.value = true;

      store
        .dispatch('queries/feedback', {
          queryId: props.resource?.resourceId,
          articleId: props.article.id,
          score: route.query.score,
          ok: false,
        })
        .finally(() => {
          feedbackLoading.value = false;
        });

      handleClose();
    };

    const fetchTranslatedBody = async () => {
      if (!translatedBody.value || translatedBodyId.value != props.article.id) {
        translationLoading.value = true;
        translatedBody.value = await api.sources.translateBody({
          sourceId: props.article.source,
          itemId: props.article.id,
        });
        translationLoading.value = false;
        translatedBodyId.value = props.article.id;
      }
    };

    const fetchAnalyseFacts = async (themodel: string) => {
      if (!translatedBody.value || translatedBodyId.value != props.article.id) {
        analysisLoading.value = true;
        emit('analysisLoadingStart', themodel);

        const endpoint = api.sources.analyseFacts;
        const result = await endpoint({
          sourceId: props.article.source,
          itemId: props.article.id,
          model: themodel,
        })
          .catch((error) => {
            setMessage()(error);
            emit('analysisLoadingError', themodel);
          })
          .finally(() => {
            analysisLoading.value = false;
            translatedBodyId.value = props.article.id;
          });
        if (result) {
          clearMessage();
          emit('analysisLoadingEnd', themodel);
          translatedBody.value =
            t('common.analyseIntro1') +
            ' <b>"' +
            props.article.title +
            ' ' +
            props.article.sourceTitle +
            ' ' +
            formattedTime.value +
            '"</b> ' +
            t('common.analyseIntro2') +
            ' ' +
            themodel +
            ' ' +
            t('common.analyseIntro3') +
            '<br/><br/> ' +
            result;
        }
      }
    };

    const fetchOriginalBody = async () => {
      translatedBody.value = '';
      translatedBodyId.value = '';
    };

    const showFeedBackButtons = computed(
      () =>
        props.resource?.resourceKind === RESOURCE_KINDS.QUERY &&
        props.resource?.hasFeedback,
    );

    const translateArticleLanguage = computed(() => {
      let spokenlangs = store.state.auth.user?.notTranslatedLanguages;

      if (spokenlangs != null && spokenlangs.length > 0) {
        if (props.article.language != null) {
          return (
            !spokenlangs.includes(props.article.language) &&
            props.article.language != 'en'
          );
        }
      }
      return true;
    });

    return {
      ARTICLE_TYPES,
      baseUri,
      formattedDate,
      formattedTime,
      resourceId,
      termsLinks,
      iframeLink,
      showFeedBackButtons,
      handleThumbsUp,
      handleThumbsDown,
      feedbackLoading,
      translationLoading,
      analysisLoading,
      fetchTranslatedBody,
      fetchOriginalBody,
      showOriginalBodyButton,
      showTranslateButton,
      showAnalysisButton,
      translatedBody,
      translatedBodyId,
      fetchAnalyseFacts,
      message,
      ...useFeatures(),
    };
  },
});
