import { ActionContext } from 'vuex';

import config from '@/config';
import environment from '@/services/environment';
import { State as R } from '@/store/index';

export interface State {
  api: {
    uri: string;
  };
}

export default {
  namespaced: true,

  state: {
    api: {
      uri: 'https://newsapi.group39.org/services/',
    },
  },

  mutations: {
    setApiEndpoint(state: State, endpoint: string): void {
      state.api.uri = endpoint;
    },
  },

  actions: {
    async fetchApiEndpoint({ commit }: ActionContext<State, R>): Promise<void> {
      commit('setApiEndpoint', config.api.uri);
    },
  },

  getters: {},
};
